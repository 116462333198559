<template>
  <div id="app">
    <header id="home">
      <div class="container clearfix">
        <div class="logo fl">
          <img src="./assets/logo.png" height="65" alt="Alitas">
        </div>
        <nav>
          <ul>
            <li @click="scrollTo('features')">Functions & Features</li>
            <li @click="scrollTo('media')">Users & Partnerships</li>
            <li><a :href="`${publicPath}Defigram_Whitepaper.pdf`" target="_blank">WhitePaper</a></li>
          </ul>
          <a href="https://api.defigram.net/download/defigram_v1.0.0.apk" target="_blank" class="download">Download</a>
        </nav>
      </div>
    </header>

    <section class="intro" id="intro"> 
      <div class="container flex">
        <div class="left">
          <h3>Defi community at your fingertips</h3>
          <p>The world's most efficient Defi ecosystem one-stop information platform</p>
          <p class="download"><a href="https://api.defigram.net/download/defigram_v1.0.0.apk" target="_blank">Download</a></p>
          <ul class="flex">
            <li><a href="https://twitter.com/HiDefigram" target="_blank"><img src="./assets/twitter.png" width="24" alt="twitter"></a></li>
            <li><a href="https://t.me/DefigramNet" target="_blank"><img src="./assets/tele.png" width="24" alt="telegram"></a></li>
            <li><a href="https://github.com/defi-gram" target="_blank"><img src="./assets/github.png" width="24" alt="github"></a></li>
            <li><a href="https://medium.com/@HiDefigram" target="_blank"><img src="./assets/medium.png" width="24" alt="medium"></a></li>
          </ul>
        </div>
        <div class="right"><img src="./assets/mobile.png" alt=""></div>
      </div>
    </section>

    <section class="why" id="why">
      <h1>Why Defigram ?</h1>
      <div class="container">
        <p class="desc">Defigram is a new social platform that combines decentralized wallets with Telegram communication. Defigram is dedicated to building the most efficient and valuable social network for Defi users.</p>
        <ul class="feat flex">
          <li>Decentralized multi-<br>chain wallet</li>
          <li>NFT social network</li>
          <li>Defi project community<br>and compatibility</li>
          <li>Fully-featured Telegram <br>integration</li>
        </ul>
        <ul class="icons flex">
          <li><img src="./assets/f1.png" width="150" alt=""></li>
          <li><img src="./assets/f2.png" width="150" alt=""></li>
          <li><img src="./assets/f3.png" width="150" alt=""></li>
          <li><img src="./assets/f4.png" width="150" alt=""></li>
          <li><img src="./assets/f5.png" width="150" alt=""></li>
          <li><img src="./assets/f6.png" width="150" alt=""></li>
        </ul>
      </div>
    </section>

    <section class="features" id="features">
      <ul class="container">
        <li>
          <p class="picture"><img src="./assets/ff1.png" width="266" alt=""></p>
          <div>
            <h3>It’s more than a wallet, it's also a social network.</h3>
            <p>i.Secure and convenient decentralized multi-chain wallet service to easily manage huge categories of digital assets under various ecologies such as BTC, ETH and BSC. </p>
            <p>ii.The most comprehensive category social network everwith updated community data day by day! Experience all your defi products in one place with Defigram.</p>
          </div>
        </li>
        <li>
          <div>
            <h3>0 distance, more than efficient!</h3>
            <p><b>All in one step! </b> <br>A community to fully understand a project, lightning fast live chat, view the latest project information with smooth communication.</p>
            <p><b>One step ahead!  </b> <br>Experience defi products directly within the group, enjoy 0 distance</p>
          </div>
          <p class="picture"><img src="./assets/ff2.png" width="246" alt=""></p>
        </li>
        <li>
          <p class="picture"><img src="./assets/ff3.png" width="246" alt=""></p>
          <div>
            <h3>Empowering NFTs, you are the master of your assets.</h3>
            <p>Supports user avatars with NFT authentication. Provides space for users to display their NFTs, creates more scenarios for NFT users, including NFT achievements, home page, avatars, dress up, collection hall and much more.</p>
          </div>
        </li>
      </ul>
    </section>

    <section class="media" id="media">
      <p>Massive users & media cooperation</p>
      <p>With large mainstream blockchain communities and user base</p>
      <p>chatting and communication capabilities for <br> 500 million+ users worldwide</p>
      <p>
        <img src="./assets/p1.png" height="45" alt=""><img src="./assets/p2.png" height="45" alt=""><img src="./assets/p3.png" height="45" alt=""><img src="./assets/p4.png" height="45" alt=""><img src="./assets/p5.png" height="45" alt=""><br>
        <img src="./assets/p6.png" height="45" alt=""><img src="./assets/p7.png" height="45"  alt=""><img src="./assets/p8.png" height="45"  alt="">
      </p>
    </section>

    <footer>
      <p>©Copyright 2021 DefiGame All rights reserved</p>
    </footer>
    <announcement />
  </div>
</template>

<script>
import Announcement from './components/Announcement.vue';
export default {
  name: 'App',
  components: {
    Announcement
  },
  data() {
		return {
      showTop: false,
      publicPath: process.env.BASE_URL,
    }
  },
  mounted() {
    window.addEventListener("scroll",()=>{
        let scrollTop = document.documentElement.scrollTop;
        if(scrollTop > 100){
            this.showTop = true;
        }else{
            this.showTop = false;
        }
    }) 
  },
  methods: {
    scrollTo(id) {
      window.document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss">
body {
    background-image: url(./assets/bghead.png);
    background-repeat: no-repeat;
    background-size: 1800px;
    background-position-y: -700px;
    background-position-x: center;
}

.container {
  width: 1080px;
  margin: 0 auto;
  position: relative;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.arrow {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  &.up {
    transform: rotate(135deg);
  }
  &.down {
    transform: rotate(-45deg);
    margin-top: -20px;
  }
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  width: 100%;
  height: 100px;
  padding-top: 16px !important;
  .logo {
    height: 100px;
    display: flex;
    align-items: center;
  }
  nav {
    font-size: 14px;
    margin-left: 32px;
    margin-top: 20px;
    position: relative;
    ul {
      display: flex;
      li {
        // flex: 1;
        line-height: 2.5;
        margin-left: 40px;
        position: relative;
        cursor: pointer;
        font-weight: bolder;
        &:hover {
          border-bottom: 5px solid #000;
        }
        a {
          color: #000;
          font-weight: bolder;
        }
      }
    }
    a.download {
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
      width:  120px;
      background: #4669e7;
      line-height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 10px;
      box-shadow: 0 3px 10px rgba(0,0,0,.4);
    }
  }
  
}

section {
  &.intro {
    div.left {
      width: 40%;
      padding-left: 20px;
      margin-top: 80px;
      h3 {
        font-size: 32px;
        font-weight:bolder;
      }
      p {
        margin: 24px 0;
        line-height: 1.4;
        &.download {
          a {
            display: inline-block;
            width:  120px;
            background: #4669e7;
            line-height: 40px;
            text-align: center;
            color: #fff;
            border-radius: 10px;
            box-shadow: 0 3px 10px rgba(0,0,0,.4);
          }
        }
      }
      ul {
        width: 140px;
        margin-top: 40px;
      }
    }
    div.right {
      position: relative;
      top: -40px;
      left: -30px;
    }
  }

  &.why {
    background: #F6F6F6;
    padding: 64px 0;
    text-align: center;
    background-image: url(./assets/bg1.png);
    background-repeat: no-repeat;
    background-size: 1560px;
    background-position: center -20px;
    h1 {
      text-align: center;
      font-size: 32px;
      margin-bottom: 24px;
      font-weight: bolder;
    }
    p.desc {
      font-size: 16px;
      padding: 0 120px;
      line-height: 1.8;
    }
    ul.feat {
      margin: 40px 0 60px;
      align-items: center;
      li {
        background: #4669e7;
        width: 23%;
        color: #fff;
        border-radius: 8px;
        line-height: 1.4;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 3px 10px rgba(0,0,0,.2);
      }
    }
  }

  &.features {
    padding: 40px 0;
    background-image: url(./assets/bg2.png);
    background-repeat: no-repeat;
    background-size: cover;
    li {
      display: flex;
      margin: 24px;
      h3 {
        font-size: 24px;
        font-weight: bolder;
        margin-bottom: 36px;
        padding-top: 40px;
      }
      p {
        line-height: 1.4;
        margin-bottom: 20px;
        padding-right: 80px;
        b {
          font-weight: bolder;
        }
      }
    }
  }

  &.media {
    background: #F6F6F6;
    padding: 60px 0;
    text-align: center;
    position: relative;
    background-image: url(./assets/bg3.png);
    background-repeat: no-repeat;
    background-size: cover;
   
    p {
      font-size: 24px;
      margin-bottom: 18px;
      line-height: 1.2;
      font-weight: bolder;
      &:nth-child(1) {
        font-weight: 400;
      }
      &:nth-child(2) {
        font-weight: lighter;
      }
      &:last-child {
        margin-top: 48px;
      }
      img {
        margin: 12px 24px;
      }
    }
  }
}

footer {
  width: 100%;
  text-align: center;
  padding: 50px 0 100px !important;
  background: #4669E7;
  color: #fff;
}

</style>
